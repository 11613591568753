// eslint-disable-next-line 
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Navigate } from "react-router";
import { Link } from 'react-router-dom';
import './App.css';
import AmazonOffset from './components/amazonOffset';
import DownloadOrder from './components/downloadOrder';
import EditOrder from './components/EditOrder/editOrder';
import FinancialPage from './components/Financial/index.js';
import FrontPage from './components/frontPage';
import Header from './components/headers/header';
import SideCart from './components/headers/sideCart';
import StaticHeader from './components/headers/staticHeader';
import HistoricalUpdates from './components/historicalUpdates';
import InventoryPage from './components/inventory/inventoryPage';
import Login from './components/login';
import NotFound from './components/notFoundPage';
import OrderPage from './components/orderPage';
import DetailsIndex from './components/PastOrders/DetailsIndex.js';
import PastOrders from './components/PastOrders/index';
import ProfitAndLoss from './components/ProfitAndLoss';
import ReceiveOrder from './components/receiveOrder';
import ScrollToTop from './components/scrollToTop';
import { TutorialModal } from './components/tutorial';
import UserSettingsPage from './components/userSettingsPage';
import ViewOrder from './components/viewOrder';

import Restock from './components/Restock/index.js';

import { IdleTimer } from './components/IdleTimer';

import Alert from 'react-bootstrap/Alert';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMenorah } from '@fortawesome/free-solid-svg-icons';


import { ProtectedRoute } from './protectedRoute';
let endpoint = "";


//let ebayLink="https://auth.ebay.com/oauth2/authorize?client_id=JustinBa-Turnpike-PRD-c3882c164-8e960b56&response_type=code&redirect_uri=Justin_Barish-JustinBa-Turnpi-dxfoe&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly"




class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      token: "",
      loginMessage: "",
      username: "",
      items: [],
      cartShow: false,
      ebayTokenValid: true,
      timingOut: true,
      loggedIn: true,
      showUpdate: 'false',
      serverItemUpdates: [],
      updatePage: 0,
      storeName: "",
      syncStatus: {},
      all_tags: [],
      searchData: {}
    };
    this.idleTimer = null
    this.timer = null
    this.updateTime = null

    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.getNewToken = this.getNewToken.bind(this)
    this.getUpdates = this.getUpdates.bind(this)
    this.getSettings = this.getSettings.bind(this)
    this.checkIfItemInCart = this.checkIfItemInCart.bind(this)
    this.updateCartItems = this.updateCartItems.bind(this)
    this.getSearchData = this.getSearchData.bind(this)

  }


  initFunctions() {
    this.checkEbayStatus()
    this.getSettings()
    this.getSyncStatus()
    this.getAllTags()
    this.getSearchData()
  }
  componentDidMount() {

    var loggedIn = localStorage.getItem('loggedIn') || ''
    this.setState({ username: localStorage.getItem('username') || '', loggedIn: loggedIn })
    this.setState({ showUpdate: localStorage.getItem('showUpdate1') || 'true' })
    this.token_timer = setInterval((e) => this.getNewToken(e), 1000 * 60 * 5)
    this.update_timer = setInterval((e) => this.getUpdates(e), 1000 * 60 * 4)//60*4)
    this.sync_timer = setInterval((e) => { this.getSyncStatus(e) }, 1000 * 60 * 2)
    this.tag_timer = setInterval((e) => this.getAllTags(e), 1000 * 60 * 3)
    if (loggedIn) {
      this.initFunctions();
    }

  }
  componentWillUnmount() {
    clearInterval(this.token_timer);
    clearInterval(this.update_timer);
    clearInterval(this.sync_timer);
    clearInterval(this.tag_timer);

  }




  getNewToken() {
    var currComponent = this

    if (this.state.loggedIn && this.state.loggedIn != "") {
      this.checkEbayStatus()
      axios.get(endpoint + '/api/get-new-token', {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      })
        .then(function (response) {
          if (response.headers) {
            currComponent.setState({ token: response.headers.bearer })
          }

        })
        .catch(function (error) {
        });
    }
  }


  getAllTags() {
    var currComponent = this


    if (this.state.loggedIn && this.state.loggedIn != "") {

      axios.get(endpoint + '/api/get-all-tags', {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      })
        .then(function (response) {
          if (response.data) {
            currComponent.setState({ all_tags: response.data })
          }

        })
        .catch(function (error) {
        });
    }
  }


  getSyncStatus(e) {
    var currComponent = this

    if (this.state.loggedIn) {

      axios.get(endpoint + '/api/get-sync-status', {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      })
        .then(function (response) {
          if (response.data) {

            currComponent.setState({ syncStatus: response.data })
          }

        })
        .catch(function (error) {
        });
    }
  }

  getSearchData(e) {
    var currComponent = this

    if (this.state.loggedIn) {

      axios.get(endpoint + '/api/get-search-data', {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      })
        .then(function (response) {
          if (response.data) {
            currComponent.setState({ searchData: response.data })
          }

        })
        .catch(function (error) {
        });
    }
  }




  getUpdates(e, incPage = false) {
    var currComponent = this


    if (this.state.loggedIn) {
      let payload = {
        limit: 5,
        page: incPage ? this.state.updatePage + 1 : this.state.updatePage
      };
      //console.log("Getting New Token")
      axios.post(endpoint + '/api/get-updates', payload, {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      })
        .then(function (response) {
          if (response.data) {

            if (incPage) {
              currComponent.setState({ serverItemUpdates: [...currComponent.state.serverItemUpdates, ...response.data.history], updatePage: currComponent.state.updatePage + 1 })
            } else {
              currComponent.setState({ updatePage: 0, serverItemUpdates: response.data.history })
            }
          }

        })
        .catch(function (error) {
        });
    }
  }

  addCartItem(item) {
    let payload = {
      group_id: item.id,
      item_sku: item.sku,
      order_id: "cart",
      item_qty: typeof item.qty === 'undefined' ? "0" : String(item.qty),

      reserve_qty: typeof item.cust_qty === 'undefined' || item.cust_qty === '' ? "0" : String(item.cust_qty),
      fba_qty: typeof item.fba_qty === 'undefined' || item.fba_qty === '' ? "0" : String(item.fba_qty),
      item_state: "cart"
    };

    axios.post(endpoint + '/api/add-cart-item',
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
      }
    })

  }

  updateCartItem(item) {

    let payload = {
      item_id: item.id,
      item_sku: item.sku,
      order_id: "cart",
      item_qty: typeof item.qty === 'undefined' ? "0" : item.qty,
      fba_qty: typeof item.fba_qty === 'undefined' ? "0" : item.fba_qty,
      reserve_qty: typeof item.cust_qty === 'undefined' ? "0" : item.cust_qty,
      item_state: "cart",
      item_cost: parseFloat(item.item_cost)

    };
    axios.put(endpoint + '/api/update-order-item/' + item.id,
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
      }
    })

  }

  UpdateCartItems(newItems) {

    //figure out what items we added to the cart
    for (var i = 0; i < newItems.length; i++) {
      var found = false
      for (var j = 0; j < this.state.items.length; j++) {
        if (newItems[i].id === this.state.items[j].id) {
          found = true
          break
        }
      }
      if (!found) {
        this.addCartItem(newItems[i])
      }
    }

    //figure out what items we removed from the cart
    for (var i = 0; i < this.state.items.length; i++) {
      var found = false
      for (var j = 0; j < newItems.length; j++) {
        if (newItems[j].id === this.state.items[i].id) {
          found = true
          break
        }
      }
      if (!found) {
        this.DeleteDBOrderItem(this.state.items[i])
      }
    }

    this.setState({ items: newItems })
  }

  DeleteDBOrderItem(item) {

    axios.delete(endpoint + '/api/delete-order-item/' + item.id,
      {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      })

  }

  loadCartItems = () => {
    let currentComponent = this;

    axios.get(endpoint + "/api/get-cart-items",
      {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          this.setState({

            items: res.data

          });

        } else {
          currentComponent.setState({
            items: []

          });
        }
      }).catch(error => {
        // handle error 
        currentComponent.setState({
          items: []
        });
      })
  };


  getSettings = () => {
    var com = this
    if (this.state.storeName.length > 0) {
      return;
    }
    axios.get(endpoint + '/api/get-settings',
      {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      })
      .then(function (response) {

        com.setState({ storeName: response.data['store_name'] })
      })
      .catch(function (error) {

      });


  }

  setLoginProperties = (data, usrname) => {
    if (data != "") {
      this.setState({ token: data, username: usrname, loggedIn: "true" }, () => { this.getSettings(); this.initFunctions() })
      localStorage.setItem('username', usrname);
      localStorage.setItem('loggedIn', "true");


    } else {
      axios.post(endpoint + "/api/logout")
      this.setState({ token: "", username: "", loggedIn: "", loginMessage: "" })
      localStorage.removeItem('username');
      localStorage.removeItem('loggedIn');
    }


  }
  setLoggedIn = () => {
    this.setState({ token: "123" })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loggedIn != "" && this.state.loggedIn != prevState.loggedIn) {
      this.loadCartItems();
      this.getUpdates();
    }
  }

  setLoginMessage = (data) => {
    this.setState({ loginMessage: data })
  }


  checkIfItemInCart = (item) => {
    for (var i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].id === item.id) {
        return this.state.items[i]
      }
    }
    return null
  }
  setItems = (item) => {
    var ret = this.checkIfItemInCart(item)
    if (ret !== null) {

      item.qty = String(parseInt(item.qty) + parseInt(ret.qty))
      item.cust_qty = String(parseInt(item.cust_qty) + parseInt(ret.cust_qty))
      item.fba_qty = String(parseInt(item.fba_qty) + parseInt(ret.fba_qty))
      this.updateCartItems(item)

    } else {
      this.addCartItem(item)
      this.setState((state) => ({ items: [item, ...state.items] }))
    }

  }

  checkEbayStatus = () => {
    let currentComponent = this;

    axios.get(endpoint + "/api/get-ebay-token-status",
      {
        headers: {
          Authorization: 'Bearer ' + this.state.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          currentComponent.setState({ ebayTokenValid: res.data.token_status })
        }


      })
  }

  setEbayTokenStatus = (status) => {
    this.setState({ ebayTokenValid: status })
  }


  updateCartItems = (my_item, is_delete = false) => {
    if (is_delete) {
      this.setState({
        items: this.state.items.filter(function (item) {

          return item.id !== my_item.id
        })
      });
      this.DeleteDBOrderItem(my_item)
    } else {
      this.setState((state) => ({ items: [my_item, ...state.items.filter(function (item) { return item.id !== my_item.id })] }))
      this.updateCartItem(my_item)
    }
  }



  openCart = () => {
    this.setState({ cartShow: !this.state.cartShow })
  }

  _onAction(e) {

  }

  _onActive(e) {
  }

  _onIdle(e) {
    axios.post(endpoint + "/api/logout")
    this.setState({ token: "", username: "", loggedIn: "", loginMessage: "You Have Been Logged Out Due To Inactivity. Please Log Back In." })
    localStorage.clear();


  }




  render() {

    return (


      <Router>
        <ScrollToTop />
        <div className="App">

          {this.state.loggedIn &&
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onActive={this.onActive}
              crossTab={true}
              leaderElection={true}
              syncTimers={200}
              name="IL"
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={1000 * 30 * 60} />
          }





          {this.state.loggedIn && <SideCart items={this.state.items} cartShow={this.state.cartShow} updateItems={this.updateCartItems} openCart={this.openCart} />}


          {this.state.loggedIn &&
            <Alert show={!this.state.ebayTokenValid} variant="danger" style={{ marginTop: 125 }}>
              Critical Error! An error is preventing communication with one or more linked platforms. <Link className="order_url" to="/user-settings?page=web-accounts">Click Here</Link> to see more details and fix.
            </Alert>


          }

          {this.state.loggedIn &&
            <Alert show={false} onClose={() => { this.setState({ showUpdate: 'false' }); localStorage.setItem('showUpdate', 'false'); }} dismissible variant="info" style={{ marginTop: 125, zIndex: 1002 }}>
              <Alert.Heading>What's New?</Alert.Heading>
              <ul style={{ textAlign: "left" }}>
                <li>Item Quantity updates will happen immediately on the webpage - no more waiting 5-10 minutes for the website to reflect the changes (updates to ebay/amazon will still take 5-10 minutes, but you will see your updates immediately on the website)</li>
                <li>Items can be manually merged on the inventory page by double clicking the item and clicking the 'Merge Item' tab</li>
                <li>Incorrectly merged items can be merged by double clicking the item and clicking the 'Unmerge Item' tab</li>
                <li>Items will no longer automatically be merged by sku / asin/etc. Items can only be merged manually using the above method.</li>
              </ul>
            </Alert>
          }

          {this.state.loggedIn && false &&
            <TutorialModal show={this.state.showUpdate == 'true'} onHide={() => { this.setState({ showUpdate: 'false' }); localStorage.setItem('showUpdate1', 'false'); }} />}

          {this.state.loggedIn && (this.state.username == 'gfdgsdf' || this.state.username == 'sfgsdgf') &&
            <Alert show={this.state.showUpdate == 'true'} onClose={() => { this.setState({ showUpdate: 'false' }); localStorage.setItem('showUpdate1', 'false'); }} dismissible variant="primary" style={{ marginTop: 76, zIndex: 1002 }}>
              <Alert.Heading><FontAwesomeIcon className="trashIcon" icon={faMenorah} /> &nbsp; Happy Hanukkah Robin!  &nbsp;<FontAwesomeIcon className="trashIcon" icon={faMenorah} /></Alert.Heading>
              You have a 'gift' waiting for you on the part orders page!
            </Alert>

          }
          {this.state.loggedIn && <Header />}
          {this.state.loggedIn && <StaticHeader loggedIn={this.state.loggedIn} callback={this.setLoginProperties} username={this.state.username} openCart={this.openCart} cartCount={this.state.items.length} loadMoreUpdates={() => this.getUpdates(null, true)} serverItemUpdates={this.state.serverItemUpdates} syncStatus={this.state.syncStatus} searchData={this.state.searchData} className="static" />}


          <Routes>


            <Route exact path='/' element={<ProtectedRoute auth={this.state.loggedIn}><FrontPage storeName={this.state.storeName} token={this.state.token} checkEbay={this.setEbayTokenStatus} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} /></ProtectedRoute>} />

            <Route exact path='/index.html' element={<ProtectedRoute auth={this.state.loggedIn}><FrontPage storeName={this.state.storeName} token={this.state.token} checkEbay={this.setEbayTokenStatus} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} /></ProtectedRoute>} />
            <Route exact path='/inventory' element={<ProtectedRoute auth={this.state.loggedIn}><InventoryPage token={this.state.token} cart_items={this.state.items} setLogcallback={this.setLoginProperties} itemupdate={this.updateCartItems} itemcallback={this.setItems} logincallback={this.setLoginMessage} allTags={this.state.all_tags} refreshTags={() => this.getAllTags()} /></ProtectedRoute>} />


            <Route exact path='/past-orders' element={<ProtectedRoute auth={this.state.loggedIn}><PastOrders token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} cart_items={this.state.items} overwriteCart={(new_cart_items) => { this.UpdateCartItems(new_cart_items) }} itemcallback={this.setItems} /></ProtectedRoute>} />

            {/* <Route exact path='/report
                      s' element={ <Reports  token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage}/>}/> */}

            <Route exact path='/all-updates' element={<ProtectedRoute auth={this.state.loggedIn}><HistoricalUpdates setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} token={this.state.token} /></ProtectedRoute>} />

            <Route exact path='/orders' element={<ProtectedRoute auth={this.state.loggedIn}><OrderPage token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} /></ProtectedRoute>} />
            <Route exact path='/item-select' element={<ProtectedRoute auth={this.state.loggedIn}><Restock token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} cart_items={this.state.items} itemupdate={this.updateCartItems} itemcallback={this.setItems} allTags={this.state.all_tags} refreshTags={() => this.getAllTags()} /></ProtectedRoute>} />


            <Route exact path='/order-edit' element={<ProtectedRoute auth={this.state.loggedIn}><EditOrder token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} cart_items={this.state.items} overwriteCart={(new_cart_items) => { this.UpdateCartItems(new_cart_items) }} itemcallback={this.setItems} /></ProtectedRoute>} />

            <Route exact path='/receive-order' element={<ProtectedRoute auth={this.state.loggedIn}><ReceiveOrder token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} /></ProtectedRoute>} />



            <Route exact path='/order-view/:id' element={<ProtectedRoute auth={this.state.loggedIn}><ViewOrder token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} /></ProtectedRoute>} />
            <Route exact path='/order-details/:id' element={<ProtectedRoute auth={this.state.loggedIn}><DetailsIndex token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} cart_items={this.state.items} overwriteCart={(new_cart_items) => { this.UpdateCartItems(new_cart_items) }} itemcallback={this.setItems} /></ProtectedRoute>} />

            <Route exact path='/order-download' element={<ProtectedRoute auth={this.state.loggedIn}><DownloadOrder token={this.state.token} setLogcallback={this.setLoginProperties} logincallback={this.setLoginMessage} /></ProtectedRoute>} />
            <Route exact path='/login' element={this.state.loggedIn ? <Navigate to="/" /> : <Login callback={this.setLoginProperties} message={this.state.loginMessage} />} />
            <Route exact path='/user-settings' element={<ProtectedRoute auth={this.state.loggedIn}><UserSettingsPage token={this.state.token} logincallback={this.setLoginMessage} setLogcallback={this.setLoginProperties} username={this.state.username} /></ProtectedRoute>} />
            <Route exact path='/amazon-offset' element={<ProtectedRoute auth={this.state.loggedIn}><AmazonOffset logincallback={this.setLoginMessage} setLogcallback={this.setLoginProperties} token={this.state.token} /></ProtectedRoute>} />
            <Route exact path='/finances' element={<ProtectedRoute auth={this.state.loggedIn}><FinancialPage logincallback={this.setLoginMessage} setLogcallback={this.setLoginProperties} token={this.state.token} /></ProtectedRoute>} />
            <Route exact path='/profit-loss-summary' element={<ProtectedRoute auth={this.state.loggedIn}><ProfitAndLoss logincallback={this.setLoginMessage} setLogcallback={this.setLoginProperties} token={this.state.token} /></ProtectedRoute>} />



            <Route path='*' element={<NotFound />} />
          </Routes>

        </div>
      </Router>


    );
  }
}

export default App;
