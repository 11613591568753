import axios from "axios";
import React, { Component, useCallback, useEffect, useMemo, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';
import { ExcelRenderer } from 'react-excel-renderer';
import ModalImage from "react-modal-image";
import ItemPropertiesModal from '../ItemModal/itemModal.js';
import { CustomToggleListLightning } from "../customToggleList.js";
import '../inventory.css';
import { withRouter } from '../withRouter.js';

import { expandingRow } from '../../lightningTable/lightning-expand.js';
import { selectableRow } from '../../lightningTable/lightning-select.js';

import { LightningKit } from '../../lightningTable/lightning-kit.js';
import { LightningCSV } from '../../lightningTable/lightning-misc.js';
import { LightningSearch } from '../../lightningTable/lightning-search.js';
import { LightningTable } from '../../lightningTable/lightning-table.js';

import { faClipboardList, faDownload, faExternalLinkAlt, faFileExcel, faPlus, faSitemap, faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';

import EnsureModal from '../ensureModal.js';
import AdvancedSearch from './advancedSearch.js';

import { FilterMenu } from '../filters/filterMenu.js';
import { InventoryFilters } from '../filters/inventoryFilters.js';

import { useDropzone } from 'react-dropzone';
import { addToQueryString, deleteFromQueryString } from '../../utils/QueryStringModder.js';
import { DismissableAlert } from "../dismissableAlert.js";

import { handleCartAddCallback } from "../ItemModal/utilFunctions.js";
import { advancedSearchFilterHelper } from "./advancedSearchFilter.js";

import { BulkEditModal } from './ItemBulkEditProperties.js';
let endpoint = "";
const FileDownload = require('js-file-download');




const narrow_columns = [
  expandingRow(), {
    accessorKey: 'item_img_url',
    header: '',
    enableSorting: false,
    editable: false,
    cell: props => {
      return <ModalImage
        className="smallimage"
        small={props.getValue()}
        large={props.getValue()}
        alt={props.row.original.item_sku}

      />
    }, width: 0.1
  }, {
    accessorKey: 'item_name',
    header: 'Product',
    enableSorting: true,
    editable: false,
    cell: props => {
      var row = props.row.original
      return (

        <div className="narrowColumnCell">
          <div className="narrowColumnTitle">
            <span>{row.item_notes && row.item_notes.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }} className="trashIcon" icon={faClipboardList} />} {props.getValue()} </span>
          </div>
          <div className="narrowColumnContentL1">SKU: &nbsp; {row.item_sku} </div>
          <div className="narrowColumnContentL1">Part Number: &nbsp; {row.item_part_num ? row.item_part_num : " - "} </div>
          <div className="narrowColumnContentL1">Cost Price: &nbsp; {row.cost_price ? `$${row.cost_price}` : " - "} </div>
          <div className="narrowColumnContentL1">List Price: &nbsp; {row.web_price_min == row.web_price_max ? `$${row.web_price_min}` : `$${row.web_price_min} - $${row.web_price_max}`} </div>
          <div className="narrowColumnContentL1">Vendor: &nbsp; {row.item_vendor ? row.item_vendor : " - "} </div>
          <div className="narrowColumnContentL1">Location: &nbsp; {row.item_warehouse_loc ? row.item_warehouse_loc : " - "} </div>
          <div className="narrowColumnContentL1">Stock Quantity: &nbsp; {row.item_qty} </div>
          <div className="narrowColumnContentL1">FBA Hold Quantity: &nbsp; {row.item_qty_reserved} </div>
          <div className="narrowColumnContentL1">FBA Quantity: &nbsp; {row.item_qty_incoming} </div>
          <div className="narrowColumnContentL1">Selling Sites: &nbsp; {row.item_type} </div>




        </div>

      )
    },
    width: 0.9
  }
]


const columnsLinkedNarrow = [{
  accessorKey: 'item_name',
  header: 'Product',
  enableSorting: true,
  editable: false,
  cell: props => {
    var row = props.row.original
    return (

      <div className="narrowColumnCell">
        <div className="narrowColumnTitle">
          <span>{props.getValue()} </span>
        </div>
        <div className="narrowColumnContentL1">SKU: &nbsp; {row.item_sku} </div>
        <div className="narrowColumnContentL1">Web ID: &nbsp; {row.web_id} </div>
        <div className="narrowColumnContentL1">Start Date: &nbsp; {row.start_date} </div>
        <div className="narrowColumnContentL1">List Price: &nbsp; {String(row.web_price).indexOf("$") >= 0 ? `${row.web_price}` : `$${row.web_price}`} </div>
        <div className="narrowColumnContentL1">Selling Site: &nbsp; {row.item_type} </div>
        <div className="narrowColumnContentL1">Listing Link: &nbsp; {props.getValue() == "" ?
          <span></span> : <span><a href={row.web_sale_link} target="_blank"><FontAwesomeIcon className="trashIcon" icon={faExternalLinkAlt} title="Go To ECommerce Item Page" /></a></span>
        } </div>
      </div>

    )
  },
  width: 0.4

}, {
  accessorKey: 'item_links',
  header: 'Item Links',
  hidden: true,
  hideInToggle: true
}, {
  accessorKey: 'item_tags_strings',
  header: 'Item Tags',
  hidden: true,
  hideInToggle: true
}, {
  accessorKey: 'item_barcode',
  header: 'Item Barcode',
  hidden: true,
  hideInToggle: true
}];

var columns = [
  expandingRow(),
  selectableRow(),
  {
    accessorKey: 'item_img_url',
    header: 'Image',
    enableSorting: false,
    editable: false,

    cell: props => {
      return <ModalImage
        className="smallimage"
        small={props.getValue()}
        large={props.getValue()}
        alt={props.row.original.item_sku}

      />
    },
    width: .05

  }, {
    accessorKey: 'item_name',
    header: 'Product Name',
    enableSorting: true,
    editable: false,
    cell: props => { return (<span>{props.row.original.item_notes && props.row.original.item_notes.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }} title="This item has notes" className="trashIcon" icon={faClipboardList} />} {props.row.original.components && props.row.original.components.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }} title="This item has components" className="trashIcon" icon={faSitemap} />}  {props.row.original.components_of && props.row.original.components_of.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }} title="This is a component of another item" className="trashIcon" icon={faSitemap} transform={{ rotate: 180 }} />} {props.getValue()} </span>) },
    width: 0.3

  }, {
    accessorKey: 'item_sku',
    header: 'Item Sku',
    enableSorting: true,
    editable: false,
    width: 0.19

  }, {
    accessorKey: 'item_part_num',
    header: 'Part Number',
    enableSorting: true,
    editable: true,
    width: 0.09

  }, {
    accessorKey: 'item_vendor',
    header: 'Vendor',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.09
  }, {
    accessorKey: 'item_identifiers',
    header: 'Identifier',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.09,
    cell: props => {
      var tmp = "[" + props.getValue() + "]"
      var id = JSON.parse(tmp)
      if (id.length > 0) {

        return (<span>{id[0]['id_type']}: {id[0]['id_value']}</span>)
      } else {
        return (<span></span>)
      }
    }

  }, {
    accessorKey: 'item_warehouse_loc',
    header: 'Item Location',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.09

  }, {
    accessorKey: 'on_order_qty',
    header: 'On Order Qty',
    enableSorting: true,
    editable: false,
    width: 0.05

  }, {
    accessorKey: 'item_qty',
    header: 'Stock Qty',
    enableSorting: true,
    editable: false,
    width: 0.05

  }, {
    accessorKey: 'item_qty_incoming',
    header: 'FBA Qty',
    enableSorting: true,
    editable: false,
    width: 0.05

  }, {
    accessorKey: 'inbound_qty',
    header: 'FBA INBND Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.05

  }, {
    accessorKey: 'inbound_working_qty',
    header: 'FBA INBND WKING Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.05

  }, {
    accessorKey: 'fulfillable_qty',
    header: 'FBA FFBLE Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.05

  }, {
    accessorKey: 'reserved_qty',
    header: 'FBA RSVD Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.05

  }, {
    accessorKey: 'unsellable_qty',
    header: 'FBA UNSBLE Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: 0.05
  }, {
    accessorKey: 'item_qty_reserved',
    header: 'FBA Hold',
    enableSorting: true,
    editable: true,
    editType: "number",
    editMin: "0",
    width: 0.05
  }, {
    accessorKey: 'web_price_min',
    header: 'List Price',
    enableSorting: true,
    editable: false,
    cell: props => {
      return (!props.row.original.web_price_max ? "" : props.getValue() != props.row.original.web_price_max ? <span>${props.getValue().toLocaleString(undefined, { minimumFractionDigits: 2 })} - ${props.row.original.web_price_max.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span> : <span>${props.getValue().toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>)

    },
    width: 0.09
  }, {
    accessorKey: 'cost_price',
    header: 'Cost Price',
    enableSorting: true,
    dataFormatter: props => {

      if (!props.getValue() || props.getValue() == 0) {
        return ""
      } else if (String(props.getValue()).indexOf("$") >= 0) {
        return props.getValue()
      } else {
        return "$" + props.getValue().toLocaleString(undefined, { minimumFractionDigits: 2 })
      }

    },
    editable: true,
    width: 0.05
  }, {
    accessorKey: 'map_cost',
    header: 'MAP Cost',
    enableSorting: true,
    hidden: true,
    dataFormatter: props => {

      if (!props.getValue() || props.getValue() == 0) {
        return ""
      } else if (String(props.getValue()).indexOf("$") >= 0) {
        return props.getValue()
      } else {
        return "$" + props.getValue().toLocaleString(undefined, { minimumFractionDigits: 2 })
      }
    }, editable: true, width: 0.05
  }, {
    accessorKey: 'stock_cost_price',
    header: 'Total Cost Value',
    enableSorting: true,
    editable: false,
    hidden: true,
    cell: props => {
      var round_cell = 0
      if (props.getValue()) {
        round_cell = Math.round(props.getValue() * 100) / 100
      }
      if (round_cell == 0) {
        return <span>-</span>
      } else if (String(round_cell).indexOf("$") >= 0) {
        return <span>{round_cell}</span>
      } else {
        return <span>${round_cell.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
      }
    }, width: 0.06
  }, {
    accessorKey: 'stock_opp_price',
    header: 'Total Opp Value',
    enableSorting: true,
    editable: false,
    hidden: true,
    cell: props => {
      var round_cell = 0
      if (props.getValue()) {
        round_cell = Math.round(props.getValue() * 100) / 100
      }
      if (round_cell == 0) {
        return <span>-</span>
      } else if (String(round_cell).indexOf("$") >= 0) {
        return <span>{round_cell}</span>
      } else {
        return <span>${round_cell.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
      }
    }, width: 0.06
  }, {
    accessorKey: 'num_linked',
    header: '# Linked Items',
    enableSorting: true,
    editable: false,
    width: 0.05
  }, {
    accessorKey: 'item_type',
    header: 'Selling Sites',
    enableSorting: true,
    editable: false,
    width: 0.10
  }, {
    accessorKey: 'item_barcode',
    header: 'Item Barcode',
    hidden: true,
    hideInToggle: true
  }, {
    accessorKey: 'item_links',
    header: 'Item Links',
    hidden: true,
    hideInToggle: true
  }, {
    accessorKey: 'item_tags_strings',
    header: 'Item Tags',
    hidden: true,
    hideInToggle: true
  }



];



const columnsLinked = [{
  accessorKey: 'item_name',
  header: 'Product Name',
  enableSorting: true,
  editable: false,
  width: 0.4
}, {
  accessorKey: 'item_sku',
  header: 'Item Sku',
  enableSorting: true,
  editable: false,
  width: 0.25
}, {
  accessorKey: 'web_sale_link',
  header: 'Sale Link',
  enableSorting: false,
  editable: false,
  cell: props => {
    if (props.getValue() == "") {
      return <span>-</span>
    } else {
      return <span><a href={props.getValue()} target="_blank"><FontAwesomeIcon className="trashIcon" icon={faExternalLinkAlt} title="Go To ECommerce Item Page" /></a></span>
    }
  },
  width: 0.04
}, {
  accessorKey: 'web_id',
  header: 'Item ID',
  enableSorting: true,
  width: 0.1
}, {
  accessorKey: 'start_date',
  header: 'Start Date',
  enableSorting: true,
  editable: false,
  width: 0.05
}, {
  accessorKey: 'web_price',
  header: 'List Price',
  enableSorting: true,
  editable: false,
  cell: props => {
    if (props.getValue() == 0) {
      return <span>-</span>
    } else if (String(props.getValue()).indexOf("$") >= 0) {
      return <span>{props.getValue()}</span>
    } else {
      return <span>${props.getValue().toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
    }
  },
  width: 0.05
}, {
  accessorKey: 'item_type',
  header: 'Selling Site',
  enableSorting: true,
  editable: false,
  width: 0.1
}];


class InventoryPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      filtered_items: [],
      filtered_only_items: [],
      items_minified: [],
      items_full: [],
      errorMessage: "",
      selectedItem: {},
      showAlert: false,
      alertMessage: "",
      alertType: "",
      showModal: false,
      fileSelectShow: false,
      uploadedData: {},
      showConfirmModal: false,
      totalOppCost: -1,
      totalStockCost: -1,
      existingItem: "",
      searchWord: "",
      submittedDownloadRequest: false,
      onlyZeroOnHand: false,
      missingCost: false,
      isMapViolation: false,
      pageWidth: 0,
      showAdvancedSearch: false,
      selectedSearchFilters: [],
      advancedSearchFilteredItems: [],
      showBulkModal: false,
      selectedBulkItems: {}
    };
    this.updateItem = this.updateItem.bind(this);
    this.updateItemLocally = this.updateItemLocally.bind(this)
    this.updateItemsLocally = this.updateItemsLocally.bind(this)
    this.updateItemQty = this.updateItemQty.bind(this);
    this.updateItemQtyLocally = this.updateItemQtyLocally.bind(this);
    this.handleColumnAddition = this.handleColumnAddition.bind(this);
    this.sendMerge = this.sendMerge.bind(this)
    this.onMergeSubmit = this.onMergeSubmit.bind(this)
    this.handleCustomClick = this.handleCustomClick.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onRowDoubleClick = this.onRowDoubleClick.bind(this)
    this.onRowDoubleClickBulkEdit = this.onRowDoubleClickBulkEdit.bind(this)
    this.expandRow = this.expandRow.bind(this)
    this.headerBoxRef = React.createRef()
    this.filterBoxRef = React.createRef()
    this.addNewItem = this.addNewItem.bind(this)
    this.clearSelectedQuery = this.clearSelectedQuery.bind(this)
    this.updateRootComponents = this.updateRootComponents.bind(this)
    this.advancedSearchFilter = this.advancedSearchFilter.bind(this)

    //this.expandRow = this.expandRow.bind(this)


  }

  updateWindowDimensions() {
    this.setState({ pageWidth: window.innerWidth });
  }

  componentDidMount() {
    this.controller = new AbortController();
    this._isMounted = true;

    const params = this.props.searchParams

    if (params.get('onlyZeroOnHand') && params.get('onlyZeroOnHand') === 'true') {
      this.setState({ onlyZeroOnHand: true })
    }
    if (params.get('missingCost') && params.get('missingCost') === 'true') {
      this.setState({ missingCost: true })
    }
    if (params.get('onlyMapViolation') && params.get('onlyMapViolation') === 'true') {
      this.setState({ isMapViolation: true })
      columns[19]['hidden'] = false
    } else {
      columns[19]['hidden'] = true
    }


    this.getItem();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
    this._isMounted = false
    window.removeEventListener('resize', this.updateWindowDimensions);

  }
  calcOpCosts = (items) => {
    var totalOpp = 0
    var totalCost = 0
    if (items == 'undefined') {
      return { 'totalOpp': -1, 'totalCost': -1 }

    }

    items.forEach(element => {

      if (element['item_summary']) {
        if (!element.item_summary.components || element.item_summary.components.length <= 0) {

          if (element.item_summary.stock_opp_price) {
            totalOpp += element.item_summary.stock_opp_price
          }
          if (element.item_summary.stock_cost_price) {
            totalCost += element.item_summary.stock_cost_price
          }
        }
      } else {
        if (!element.components || element.components.length <= 0) {
          if (element.stock_opp_price) {
            totalOpp += element.stock_opp_price
          }
          if (element.stock_cost_price) {
            totalCost += element.stock_cost_price
          }
        }
      }

    });
    totalOpp = Math.round(totalOpp * 100) / 100
    totalCost = Math.round(totalCost * 100) / 100
    return { 'totalOpp': totalOpp, 'totalCost': totalCost }

  }

  filterNameToField = (filterName) => {
    var field = ""
    switch (filterName) {
      case "Item Name":
        field = "item_name"
        break;
      case "Item Sku":
        field = "item_sku"
        break;
      case "Part Number":
        field = "item_part_num"
        break;
      case "Vendor":
        field = "item_vendor"
        break;
      case "Item Tag":
        field = "item_tags"
        break;
      default:
        field = ""
    }
    return field
  }


  advancedSearchFilter = (filters) => {
    var tmpFilteredItems = [...this.state.filtered_only_items]

    if (filters.length > 0) {
      tmpFilteredItems = advancedSearchFilterHelper(filters, tmpFilteredItems)
    }
    this.setState({ selectedSearchFilters: [...filters], showAdvancedSearch: false, filtered_items: [...tmpFilteredItems], advancedSearchFilteredItems: [...tmpFilteredItems] })
  }


  filterItems = (onlyZeroOnHand, missingCost, isMapViolation, createdDate = "|", hasNotes = false, hasComponents = false, isComponents = false, toggleProps = undefined) => {

    var tmpItems = this.state.selectedSearchFilters.length > 0 ? [...this.state.advancedSearchFilteredItems] : [...this.state.items]
    if (onlyZeroOnHand) {
      tmpItems = tmpItems.filter(function (item) {
        return item.item_qty + item.item_qty_reserved === 0
      })
    }
    if (isMapViolation) {
      tmpItems = tmpItems.filter(function (item) {
        return item.map_cost > item.web_price_min
      })
      if (toggleProps) {
        var tmpVis = { ...toggleProps.columnVisibility }
        tmpVis['map_cost'] = true
        toggleProps.setColumnVisibility(tmpVis)
      }
    }
    if (missingCost) {
      tmpItems = tmpItems.filter(function (item) {
        return item.cost_price == 0
      })
    }

    if (hasNotes) {
      tmpItems = tmpItems.filter(function (item) {
        return item.item_notes.trim().length > 0
      })
    }

    if (hasComponents) {
      tmpItems = tmpItems.filter(function (item) {
        return item.components && item.components.length > 0
      })
    }

    if (isComponents) {
      tmpItems = tmpItems.filter(function (item) {
        return item.components_of && item.components_of.length > 0
      })
    }

    if (createdDate !== "|") {
      var dates = createdDate.split("|")
      if (dates.length == 2) {
        tmpItems = tmpItems.filter(function (item) {
          var found = false
          for (var i = 0; i < item.linked_items.length; i++) {
            if (item.linked_items[i].start_date > dates[0] &&
              item.linked_items[i].start_date < dates[1]) {
              found = true
              break
            }
          }
          return found
        })
      }
    }
    this.setState({ filtered_items: [...tmpItems], filtered_only_items: [...tmpItems] })
  }
  filterCallback = (allValues, updatedFilter, subFilterName, toggleProps) => {

    //console.log(allValues)
    //console.log(updatedFilter)
    //console.log(subFilterName)




    if (updatedFilter === undefined || (updatedFilter.callbackName === "CustomFilter" || updatedFilter.callbackName === "createdDate")) {
      this.filterItems(allValues.quantityIs0, allValues.missingCost, allValues.isMapViolation, allValues.createdDate, allValues.hasNotes, allValues.hasComponents, allValues.isComponents, toggleProps)
    }

  }


  submitReport = (e) => {
    e.preventDefault()
    var payload = {}
    var component = this;

    component.setState({ submittedDownloadRequest: true })
    axios.post(endpoint + '/api/generate-item-report',
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token, //the token is a variable which holds the token

      }, signal: this.controller.signal, responseType: 'blob'
    })
      .then(function (response) {
        //console.log("Downloading")
        var fileName = 'Item Report'

        fileName += '.xlsx'
        FileDownload(response.data, fileName);
        component.setState({ submittedDownloadRequest: false })

      })
      .catch(function (error) {

        component.setState({ submittedDownloadRequest: false })
      });

  }

  getItem = () => {
    let currentComponent = this;
    this.setState({ errorMessage: "Loading Data. Please Wait..." })
    axios.get(endpoint + "/api/items",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },
        signal: this.controller.signal
      }).then(res => {

        if (res.data) {
          var opCosts = currentComponent.calcOpCosts(res.data)

          var processed_items = res.data.map(item => {
            var tmp = item.item_summary
            tmp['item_links'] = ""
            tmp['item_tags_strings'] = JSON.stringify(item.item_summary.item_tags)
            item.linked_items.forEach(item => {
              Object.keys(item).forEach(function (key) {
                tmp['item_links'] += key + ":" + item[key] + ", ";
              })
            })
            tmp['linked_items'] = item.linked_items

            return tmp;
          })

          const params = currentComponent.props.searchParams
          if (params.get('selectedItem')) {
            var selected_id = params.get('selectedItem')
            var selected_row = null
            for (var i = 0; i < processed_items.length; i++) {
              if (processed_items[i].group_id === selected_id) {
                selected_row = processed_items[i]
                break
              }
            }
            if (selected_row !== null) {
              currentComponent.onRowDoubleClick(selected_row)
            }
          }

          currentComponent.setState({
            items: processed_items,
            filtered_items: processed_items,
            items_full: res.data,
            items_minified: res.data.map(item => item.item_summary),
            errorMessage: "",
            totalOppCost: opCosts['totalOpp'],
            totalStockCost: opCosts['totalCost']

          }, () => currentComponent.filterItems(currentComponent.state.onlyZeroOnHand, currentComponent.state.missingCost, currentComponent.state.isMapViolation));

        } else {
          this.setState({
            items: [],
            items_minified: [],
            items_full: []

          });
        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        console.log(error)
        currentComponent.setState({

          items: [],
          errorMessage: "Error Fetching Items. Consider logging back in"
        });
        if (!error.response || error.response.status == "401") {
          this.props.setLogcallback("", "")
          this.props.logincallback("Error! You have been logged out. Please log back in")
          currentComponent.props.navigate('/index.html')
        }
      });
  };


  uploadPartNumbers(items) {
    axios.post(endpoint + '/api/update-part-numbers',
      items, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {
        ;

        ////console.log(response.headers);
      })
      .catch(function (error) {

      });
  }

  sendMerge() {
    var com = this
    axios.get(endpoint + '/api/merge-items',
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }
      })
      .then(function (response) {
        if (com._isMounted) {
          com.setState({
            showAlert: true,
            alertMessage: "Successfully Merged Items",
            alertType: "success",
            showConfirmModal: false
          })
        }

      })
      .catch(function (error) {
        if (com._isMounted) {
          com.setState({
            showAlert: true,
            alertMessage: "Unable To Merge Items; Server Error",
            alertType: "danger",
            showConfirmModal: false
          })
        }
      });
  }



  updateItemsLocally = (items, remoteExecutionSuccess) => {
    items.forEach(item => {
      this.updateItemLocally(item.updated_fields, item.updated_item, remoteExecutionSuccess);
    });
  }

  updateItemLocally(cols, item, remoteExecutionSuccess) {

    var newItems = []
    var newItemsFiltered = []
    var newItemsMin = []
    var newItemsFull = []


    if (cols.length > 0 && cols[0] === "inactive") {
      newItems = this.state.items.filter(function (e) {
        return e.group_id !== item.group_id
      })
      newItemsFiltered = this.state.filtered_items.filter(function (e) {
        return e.group_id !== item.group_id
      })
      newItemsMin = this.state.items_minified.filter(function (e) {
        return e.group_id !== item.group_id
      })
    } else if (item.newItem) {
      if (!item.item_qty) {
        item.item_qty = 0
      }
      newItems = [...this.state.items, item]
      newItemsFiltered = [...this.state.filtered_items, item]
      newItemsMin = [...this.state.items_minified, item]

    } else {
      newItems = this.state.items.map(e => {
        if (e.group_id === item.group_id) {
          for (var i = 0; i < cols.length; i++) {
            e[cols[i]] = item[cols[i]]
          }
        }
        return e;
      })

      newItemsFiltered = this.state.filtered_items.map(e => {
        if (e.group_id === item.group_id) {
          for (var i = 0; i < cols.length; i++) {
            e[cols[i]] = item[cols[i]]
          }
        }
        return e;
      })

      newItemsMin = this.state.items_minified.map(e => {
        if (e.group_id === item.group_id) {
          for (var i = 0; i < cols.length; i++) {
            e[cols[i]] = item[cols[i]]
          }
        }
        return e;
      })

      newItemsFull = this.state.items_full.map(e => {
        if (e.item_summary.group_id === item.group_id) {
          for (var i = 0; i < cols.length; i++) {
            e[cols[i]] = item[cols[i]]
            if (cols[i] == "linked_items") {
              e['linked_items'] = item['linked_items']
            }
          }
        }
        return e;
      })
    }
    if (this._isMounted) {
      if (remoteExecutionSuccess) {
        this.setState({
          showAlert: true,
          items: [...newItems],
          filtered_items: [...newItemsFiltered],
          items_minified: [...newItemsMin],
          alertMessage: "Successfully Updated Item",
          alertType: "success"
        })
        this.props.refreshTags()
      } else {
        this.setState({
          showAlert: true,
          alertMessage: "Unable To Update Items; Server Error",
          alertType: "danger"
        })
      }
    }

  }

  updateItem(cols, item) {

    var payload = { updated_fields: cols, updated_item: item }
    var com = this
    axios.put(endpoint + '/api/update-item/' + item.description_id,
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {
        com.updateItemLocally(cols, item, true)

      })
      .catch(function (error) {
        com.updateItemLocally(cols, item, false)
      });

  }





  updateRootComponents(newComponents, item, item_to_delete) {
    var newItems = this.state.items.map(e => {
      if (e.group_id === item.group_id) {
        e['components'] = newComponents
      }
      return e;
    })

    var newItemsFiltered = this.state.filtered_items.map(e => {
      if (e.group_id === item.group_id) {
        e['components'] = newComponents
      }
      return e;
    })

    var newItemsMin = this.state.items_minified.map(e => {
      if (e.group_id === item.group_id) {
        e['components'] = newComponents
      }
      return e;
    })



    if (item_to_delete) {
      newItems = newItems.map(e => {
        if (e.group_id === item_to_delete.child_group_id) {
          e.components_of = e.components_of.filter(function (item) {
            return item.parent_group_id !== item_to_delete.parent_group_id
          })
        }
        return e;
      })

      newItemsFiltered = newItemsFiltered.map(e => {
        if (e.group_id === item_to_delete.child_group_id) {
          e.components_of = e.components_of.filter(function (item) {
            return item.parent_group_id !== item_to_delete.parent_group_id
          })
        }
        return e;
      })

      newItemsMin = newItemsMin.map(e => {
        if (e.group_id === item_to_delete.child_group_id) {
          e.components_of = e.components_of.filter(function (item) {
            return item.parent_group_id !== item_to_delete.parent_group_id
          })
        }
        return e;
      })

    } else {
      for (var i = 0; i < newComponents.length; i++) {
        var component = newComponents[i]
        var component_to_insert = { "parent_group_id": component.parent_group_id, "child_group_id": component.child_group_id, "quantity": component.quantity, "item_sku": item.item_sku, "item_name": item.item_name, 'item_img_url': item.item_img_url }
        newItems = newItems.map(e => {
          if (e.group_id === component.child_group_id) {
            var found = false;
            for (var j = 0; j < e.components_of.length; j++) {
              if (e.components_of[j].parent_group_id === item.group_id) {
                e['components_of'][j] = component_to_insert
                found = true
              }
            }
            if (!found) {
              e.components_of = [component_to_insert, ...e.components_of]
            }
          }
          return e;
        })

        newItemsFiltered = newItemsFiltered.map(e => {
          if (e.group_id === component.child_group_id) {
            var found = false;
            for (var j = 0; j < e.components_of.length; j++) {
              if (e.components_of[j].parent_group_id === item.group_id) {
                e['components_of'][j] = component_to_insert
                found = true
              }
            }
            if (!found) {
              e.components_of = [component_to_insert, ...e.components_of]
            }
          }
          return e;
        })

        newItemsMin = newItemsMin.map(e => {
          if (e.group_id === component.child_group_id) {
            var found = false;
            for (var j = 0; j < e.components_of.length; j++) {
              if (e.components_of[j].parent_group_id === item.group_id) {
                e['components_of'][j] = component_to_insert
                found = true
              }
            }
            if (!found) {
              e.components_of = [component_to_insert, ...e.components_of]
            }
          }
          return e;
        })
      }
    }




    this.setState({
      items: [...newItems],
      filtered_items: [...newItemsFiltered],
      items_minified: [...newItemsMin],
    })
  }



  onMergeSubmit(payload, remoteExecutionSuccess) {

    if (remoteExecutionSuccess && this._isMounted) {
      this.getItem()
    }

  }



  updateItems(cols, items) {

    var payload = { updated_fields: cols, updated_items: items }
    axios.post(endpoint + '/api/update-items',
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {

      })
      .catch(function (error) {

      });
  }
  updateItemQtyLocally(payload, remoteExecutionSuccess) {
    if (this._isMounted) {
      if (remoteExecutionSuccess) {
        this.setState({
          items: this.state.items.map(e => (e.group_id === payload[0].item_id ? Object.assign({}, e, { item_qty: payload[0].item_qty + payload[0].item_qty_delta }) : e)),
          filtered_items: this.state.filtered_items.map(e => (e.group_id === payload[0].item_id ? Object.assign({}, e, { item_qty: payload[0].item_qty + payload[0].item_qty_delta }) : e)),

          items_minified: this.state.items_minified.map(e => (e.group_id === payload[0].item_id ? Object.assign({}, e, { item_qty: payload[0].item_qty + payload[0].item_qty_delta }) : e)),

          showAlert: true,
          alertMessage: "Successfully Updated Item Qty",
          alertType: "success"

        })
      } else {
        this.setState({
          showAlert: true,
          alertMessage: "Unable To Update Item Qty; Server Error",
          alertType: "danger"
        })
      }
    }


  }

  updateItemQty(payload) {
    var component = this
    ////console.log(payload)
    axios.post(endpoint + '/api/update-item-qty',
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {
        component.updateItemQtyLocally(payload, true)

      })
      .catch(function (error) {
        component.updateItemQtyLocally(payload, false)
      });
  }


  handleCustomClick() {
    var selected_item = { group_id: uuidv4(), item_name: "Custom Item: " + this.state.searchWord, item_sku: this.state.searchWord, custom: 1 }
    this.onRowDoubleClick(selected_item)

  }

  handleColumnAddition(data) {

    this.setState({ fileSelectShow: false })

    var my_items = [...this.state.items]
    var updated_items = []
    for (let index = 0; index < my_items.length; ++index) {
      var found = false
      for (let rowCounter = 0; rowCounter < data.data.rows.length; rowCounter++) {
        var entry = data.data.rows[rowCounter]
        if (!entry[data.part_col]) {
          continue;
        }
        var part_num = entry[data.part_col].toString()

        if (part_num && part_num.length > 4) {


          var item = my_items[index]

          if (item.item_part_num != "") {
            if (part_num.toString().toLowerCase() == item.item_part_num.toString().toLowerCase()) {
              found = true
            }
          }
          if (!found && item.item_sku != "") {
            var name_words = item.item_sku.split(" ")

            name_words.forEach(function (word) {
              if (part_num.toString().toLowerCase() == word.toLowerCase() && word.length >= 4) {
                found = true
              }

            })
          }
          if (!found && item.item_name != "") {
            var name_words = item.item_name.split(" ")

            name_words.forEach(function (word) {
              if (part_num.toString().toLowerCase() == word.toLowerCase() && word.length >= 5) {
                found = true
              }
            })
          }
          if (!found && item.item_name != "") {
            var name_words = item.item_name.replace(/\s+/g, '')


            if (name_words.toLowerCase().includes(part_num.toString().toLowerCase()) && part_num.toString().length > 8) {
              found = true
            }

          }

          if (!found && item.item_sku != "") {
            var name_words = item.item_sku.replace(/\s+/g, '')


            if (name_words.toLowerCase().includes(part_num.toString().toLowerCase()) && part_num.toString().length > 8) {
              found = true
            }

          }


          if (found) {

            if (data.cost_col >= 0) {
              my_items[index].cost_price = Math.round((parseFloat(entry[data.cost_col]) + Number.EPSILON) * 100) / 100
            }
            if (data.percent_off > 0) {
              my_items[index].cost_price *= 1 - data.percent_off
            }
            updated_items.push({ description_id: item.description_id, item_part_num: part_num, cost_price: my_items[index].cost_price })
            my_items[index].item_part_num = part_num
            break;
          }
        }
      }




    }
    this.setState({
      items: my_items
    })
    this.updateItems(['cost_price', 'item_part_num'], updated_items)
  }

  expandRow(row) {
    return (
      <LightningKit columns={this.state.pageWidth > 550 ? columnsLinked : columnsLinkedNarrow}
        getRowCanExpand={() => false}
        in_data={this.state.items_full.filter(function (item) {
          return item.item_summary.group_id == row.original.group_id
        })[0].linked_items}
        width="100%" >
        {
          props => (
            <LightningTable className="lightningFixedTable" height="100%" maxheight="500px" {...props} {...props.table} noDataIndication="No Data to Show" />
          )}

      </LightningKit>
    )
  }


  addNewItem() {
    this.setState({ showModal: true, selectedItem: { newItem: true }, existingItem: "" })
  }

  onRowDoubleClick(row) {
    var existing_Item = this.props.cart_items.filter(function (item) {
      return item.id === row.group_id
    })[0]

    if (!existing_Item) {
      existing_Item = ""
    }

    const iter = this.props.searchParams.entries()

    var res = addToQueryString(iter, "selectedItem", row.group_id)

    this.props.setSearchParams(res)
    this.setState({ showModal: true, selectedItem: row, existingItem: existing_Item })
  }


  onRowDoubleClickBulkEdit(rows) {
    console.log(rows.rows)
    this.setState({ showBulkModal: true, selectedBulkItems: rows.rows })
  }


  clearSelectedQuery() {
    const iter = this.props.searchParams.entries()

    var res = deleteFromQueryString(iter, "selectedItem")

    this.props.setSearchParams(res)
  }

  options = {
    paginationSize: 5,
    sizePerPage: 100,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

    showTotal: true,
    sizePerPageList: [{
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '500', value: 500
    }] // A numeric array is also available. the purpose of above example is custom the text
  };



  render() {


    const afterSearch = (filter, _newResult, newResult) => {

      var opCosts = this.calcOpCosts(newResult.map(row => { return row.original }))
      this.setState({
        searchWord: filter, totalOppCost: opCosts['totalOpp'],
        totalStockCost: opCosts['totalCost']
      });

    };

    function ColDropdown(props) {

      const [selected_col, setSelectedCol] = useState("Select Column");

      const onClickHandler = (name, key) => {
        if (name == "") {
          setSelectedCol("Select Column")
        } else {
          setSelectedCol(name)
        }
        props.setSelectedCol(key)

      }
      return (
        <DropdownButton variant="info" title={selected_col} style={{ marginLeft: 10 }}>
          <Dropdown.Item key={1} onClick={() => onClickHandler("", -1)}>&nbsp;</Dropdown.Item>
          {props.cols.map(col =>
            <Dropdown.Item key={col.key} onClick={() => onClickHandler(col.name, col.key)}>{col.name}</Dropdown.Item>

          )}
        </DropdownButton>

      )

    }

    function FileModal(props) {

      const [selected_part_col, setSelectedPartCol] = useState(-1);
      const [selected_w1_col, setSelectedW1Col] = useState(-1);
      const [show_alert, setAlertShow] = useState(false);
      const [buttonEnabled, setButtonEnabled] = useState(true);
      const [button_text, setButtonText] = useState("Upload");
      const [isLoading, setLoading] = useState(false);
      var discount = React.createRef();


      const getVal = (row, idx) => {
        if (idx < 0) {
          return "No Column Selected"
        } else {
          return row[idx]
        }

      }

      useEffect(() => {
        if (isLoading) {
          props.handleSubmit({ data: props.data, part_col: selected_part_col, cost_col: selected_w1_col, percent_off: discount.value })
        }
      }, [isLoading]);

      const my_handleSubmit = () => {
        if (selected_part_col == -1 || (selected_w1_col == -1) || (discount.value >= 1.0)) {
          setAlertShow(true)
        } else {
          setAlertShow(false)
          setButtonText("Uploading")

          setButtonEnabled(false)

          setLoading(true);

        }
      }



      return (
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >


          <Modal.Header closeButton>
            <Modal.Title >
              <h2>File Upload</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert show={show_alert} variant="danger" onClose={() => setAlertShow(false)} dismissible>
              Error! Must provide part number column and a cost column, and percent must be &lt; 1.0
            </Alert>
            <h3>{props.data.name}</h3>

            {props.data.cols &&
              <Table striped bordered responsive size="sm">
                <thead>
                  <tr>
                    {props.data.cols.map(c =>

                      <th key={c.key}>{c.name}</th>
                    )
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {props.data.rows[0].map((r, index) =>

                      <td key={`0-${r}-${index}`}>{r}</td>
                    )
                    }
                  </tr>
                  <tr>
                    {props.data.rows[2].map((r, index) =>

                      <td key={`1-${r}-${index}`}>{r}</td>
                    )
                    }
                  </tr>
                  <tr>
                    {props.data.rows[3].map((r, index) =>

                      <td key={`2-${r}-${index}`}>{r}</td>
                    )
                    }
                  </tr>
                  <tr>
                    {props.data.rows[4].map((r, index) =>

                      <td key={`3-${r}-${index}`}>{r}</td>
                    )
                    }
                  </tr>
                </tbody>
              </Table>
            }
            {props.data.cols &&
              <Form onSubmit={props.my_handleSubmit} >

                <Form.Group controlId="formValue" >

                  <Form.Label>Column with part number:</Form.Label>
                  <Form.Control as={ColDropdown} cols={props.data.cols} setSelectedCol={setSelectedPartCol} />
                  <Form.Label>{getVal(props.data.rows[0], selected_part_col)}</Form.Label>

                </Form.Group>

                <Form.Group controlId="formValue2" >

                  <Form.Label>Column with Cost Price:</Form.Label>
                  <Form.Control as={ColDropdown} cols={props.data.cols} setSelectedCol={setSelectedW1Col} />
                  <Form.Label>{getVal(props.data.rows[0], selected_w1_col)}</Form.Label>

                </Form.Group>
                <Form.Group as={Col} controlId="formValue5a">
                  <Form.Label>Discount (on top of listed prices - enter as decimal, i.e. for 40% enter .4)</Form.Label>
                  <Form.Control style={{ maxWidth: 125 }} ref={(input) => discount = input} defaultValue="0" step="0.01" type="number" min="0" max="1" />
                </Form.Group>
              </Form>
            }
          </Modal.Body>
          <Modal.Footer>
            <p className="errorTable">{props.my_err}</p>
            <Button onClick={props.onHide}>Cancel</Button>
            <Button type="submit" onClick={my_handleSubmit} disabled={!buttonEnabled}>{button_text}</Button>
          </Modal.Footer>
        </Modal>
      );
    }


    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      margin: 0,
      cursor: "pointer"
    };

    const activeStyle = {
      borderColor: '#2196f3'
    };

    const acceptStyle = {
      borderColor: '#00e676'
    };

    const rejectStyle = {
      borderColor: '#ff1744'
    };

    function MyDropzone(props) {
      const onDropAccepted = useCallback(acceptedFiles => {
        ExcelRenderer(acceptedFiles[0], (err, resp) => {
          if (err) {
          }
          else {
            /*this.setState({
               cols: resp.cols,
               rows: resp.rows
             });*/
            props.setData(acceptedFiles[0].name, resp.cols, resp.rows)
          }
        });



      }, [])
      const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
      } = useDropzone({ accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], 'application/vnd.ms-excel': ['.xls'] }, onDropAccepted });

      const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject,
        isDragAccept
      ]);



      //const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

      return (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragReject && (<p>This file is not allowed</p>)}
          {
            isDragActive ?

              <p style={{ marginBottom: 0 }}>Drop cost file here, or click to select file</p> :
              <p style={{ marginBottom: 0 }}>Drag cost file here, or click to select file</p>

          }
        </div>
      )
    }




    return (


      <div className="wrapper fullPageWrapper">



        <FileModal
          show={this.state.fileSelectShow}
          onHide={() => this.setState({
            fileSelectShow: false
          })}
          data={this.state.uploadedData}
          itemData={this.state.items}
          handleSubmit={this.handleColumnAddition}
        />
        <DismissableAlert show={this.state.showAlert} type={this.state.alertType} hideCallback={() => this.setState({ showAlert: false })} message={this.state.alertMessage} />
        <AdvancedSearch show={this.state.showAdvancedSearch} onHide={() => this.setState({ showAdvancedSearch: false })} existingFilters={this.state.selectedSearchFilters} onCancel={() => this.setState({ showAdvancedSearch: false })} onSubmit={this.advancedSearchFilter} />
        <BulkEditModal items={this.state.selectedBulkItems}
          token={this.props.token}
          show={this.state.showBulkModal}
          onHide={() => this.setState({ showBulkModal: false })}
          my_updateItem={this.updateItemsLocally}
          allTags={this.props.allTags} />
        <ItemPropertiesModal
          show={this.state.showModal}
          onHide={() => {
            this.clearSelectedQuery(); this.setState({
              showModal: false
            })
          }}
          item={this.state.selectedItem}
          readOnly={false}
          my_updateItem={this.updateItemLocally}
          updateItemQty={this.updateItemQtyLocally}
          all_items={this.state.items_full}
          items_minified={this.state.items_minified}
          onMergeSubmit={this.onMergeSubmit}
          handleCartAdd={(payload, ei) => handleCartAddCallback(ei, payload, this.props.itemupdate, this.props.itemcallback)}
          my_ei={this.state.existingItem}
          showMergePanels={true}
          token={this.props.token}
          allTags={this.props.allTags}
          refreshTags={this.props.refreshTags}
          updateRootComponents={this.updateRootComponents}
          defaultTab={this.state.selectedItem.custom && this.state.selectedItem.custom == 1 ? 'addcart' : 'details'}
        />
        <EnsureModal onSubmit={this.sendMerge} show={this.state.showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}
          message="Are you sure you want to Merge items? Any items with the same SKU or web id, or items where the web id is contained in another item's sku will be merged togther. ALERT! This cannot be undone!" />

        <div className="customtable widetable">

          <LightningKit columns={this.state.pageWidth > 550 || this.state.pageWidth == 0 ? columns : narrow_columns} afterFilter={afterSearch} pageOptions={this.options} showQtyPerPageDropdown={this.state.pageWidth > 550 || this.state.pageWidth == 0} getRowCanExpand={() => true} in_data={this.state.filtered_items} width="100%" expandRow={this.expandRow}
            defaultSort={[{ id: 'item_name', desc: false }]}
            onEditCallback={(newValue, oldValue, row, column,) => {
              var id = column.id

              if (oldValue != newValue) {
                var data = {}
                if (id == 'item_part_num') {

                  data[id] = newValue;
                  data['description_id'] = row.original.description_id;
                  this.uploadPartNumbers([data])
                } else {

                  if (id == 'cost_price' || id == "map_cost") {
                    var use = newValue
                    var n = use.indexOf("$");
                    if (n >= 0) {

                      use = use.substring(n + 1, use.length)
                    }
                    if (use.length == 0) {
                      data[id] = 0
                    } else {
                      data[id] = parseFloat(use)

                      if (Number.isNaN(data[id])) {
                        return false;
                      }
                    }
                  } else {
                    if (id == 'item_qty_reserved') {
                      data[id] = parseInt(newValue)

                      if (Number.isNaN(data[id])) {
                        return false;
                      }
                    } else {
                      data[id] = newValue;
                    }
                  }
                  data['description_id'] = row.original.description_id;
                  data['group_id'] = row.original.group_id;
                  this.updateItem([id], data)

                }
                return true;
              }
              return false;

            }}>
            {
              props => (
                <div>
                  <div className="headerBox" ref={this.headerBoxRef} >
                    <Container fluid>
                      <Row>
                        <Col sm={6}>
                          {Object.keys(props.table.getState().rowSelection).length > 1 ? <h1 className="left-align">Manage Inventory (Bulk Edit)</h1> : <h1 className="left-align">Manage Inventory</h1>}
                        </Col>
                        <Col sm={6}>
                          {this.state.pageWidth > 550 && <CustomToggleListLightning columns={props.table.getAllLeafColumns()} />}
                          <FilterMenu inputFilters={InventoryFilters({ "quantityIs0": () => this.state.onlyZeroOnHand, "missingCost": () => this.state.missingCost, "isMapViolation": () => this.state.isMapViolation, "hasComponents": false, "isComponents": false, "createdDate": "|", "hasNotes": false })} filterCallback={(allValues, updatedFilter, subFilterName) => this.filterCallback(allValues, updatedFilter, subFilterName, props.toggleProps)} refreshFilterCallback={this.state.refreshFilterCallback} />
                          {/*<OverlayTrigger
                            rootClose
                            trigger="click"
                            placement="left"
                            overlay={
                              <Popover style={{ maxWidth: 400, overflowY: "scroll", maxHeight: "95vh", overflowX: "hidden" }} >
                                <Popover.Header as="h3">How to Use this Page</Popover.Header>
                                <Popover.Body>
                                  <strong>Reviewing Inventory</strong>
                                  <hr />
                                  <p>The point of this screen is to review your E-commerce inventory.  Use this page to review your inventory, as well as update important fields. Double click on any row to view more information about the item. Click the + sign to view all linked items. Linked items will share the same qty and will be kept in sync with eachother.</p>
                                  <strong>Search Item</strong>
                                  <hr />
                                  <p>To search for an item, type in a search term, or click in the search box and scan a barcode.</p>
                                  <strong>Edit Part Number</strong>
                                  <hr />
                                  <p>To edit the part number of an item, click on the cell in the table. Enter the part number for the item, and click anywhere else on the page, or hit enter. Part numbers are used to correlate uploaded inventory files and cost data. Part numbers may be automatically determined when uploading data, but may be calculated incorrectly, so use this page to correct any errors, and to manually enter any missing part numbers to improve upload accuracy.</p>
                                  <strong>Edit Part Cost</strong>
                                  <hr />
                                  <p>To edit the cost of a part (your cost from the vendor), click on the cell in the table. Enter the cost, and click anywhere else on the page, or hit enter. Alternatively, use the upload cost data button to upload cost data.</p>
                                  <strong>Edit Barcode, Order URL, and/or Qty</strong>
                                  <hr />
                                  <p>To edit any of these fields, double click on a row of data, and enter in the new values. Order URL is displayed on the view order screen. Barcode is used for receiving. To upload it, click in the text box and scan the barcode. When uploading item qty, it may take up to 5 minutes for the item qty to update. Click save to update the values.</p>


                                </Popover.Body>
                              </Popover>

                            }
                          >
                            <Button title="Help" style={{ float: "right", color: "black", marginRight: 15 }} variant="light"><FontAwesomeIcon className="trashIcon" icon={faQuestion}></FontAwesomeIcon></Button>

                          </OverlayTrigger>*/}

                          <LightningCSV style={{ float: "right", marginRight: 15 }} text={<FontAwesomeIcon className="trashIcon" icon={faDownload} />} filename="Inventory.csv" table={props.table} title="Download Grouping CSV"></LightningCSV>

                          <Button title="Download Item Worksheet" className="btn-light" disabled={this.state.submittedDownloadRequest} onClick={this.submitReport} style={{ float: "right", marginRight: 15 }}> <FontAwesomeIcon className="trashIcon" icon={faFileExcel} /></Button>
                          <Button title="Add New Non-Ecommerce Item" className="btn-light" onClick={this.addNewItem} style={{ float: "right", marginRight: 15 }}> <FontAwesomeIcon className="trashIcon" icon={faPlus} /></Button>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div className="grayheader headerBox" ref={this.filterBoxRef}>
                    <Container fluid>
                      <Row style={{ marginBottom: 10 }}>
                        <Col md={4}>
                          <Row>
                            <div id="search-left" >
                              <h2>Search:</h2>
                              <LightningSearch {...props.searchProps} />
                              <Button variant="light" style={{ marginLeft: 5 }} title="Advanced Search" onClick={() => this.setState({ showAdvancedSearch: true })}><FontAwesomeIcon className="trashIcon" icon={faSliders} /></Button>

                            </div>
                          </Row>
                          {this.state.selectedSearchFilters.length > 0 && <Row style={{ margin: 5 }}>
                            <div style={{ display: "inline-block", textAlign: "left", marginLeft: "25px" }}>
                              {this.state.selectedSearchFilters.map((filter, index) => {
                                return (
                                  <div key={index} style={{ alignItems: "center", display: "inline-block", fontSize: ".8rem", backgroundColor: "#f0f0f0", padding: 5, margin: 3, borderRadius: 8 }}>
                                    <span>{filter.field}</span>
                                    <span>&nbsp;'{filter.operator}'</span>
                                    <span>&nbsp;{filter.value}</span>
                                    <span>&nbsp;{filter.ignoreCase ? " (Ignoring Case)" : ""}</span>
                                  </div>
                                )
                              })}
                            </div>
                          </Row>

                          }
                        </Col>
                        <Col md={4}>
                          {this.state.pageWidth > 550 && <MyDropzone setData={(set_name, set_cols, set_rows) => this.setState({
                            uploadedData: { name: set_name, cols: set_cols, rows: set_rows },
                            fileSelectShow: true
                          })} />}

                        </Col>


                        {this.state.pageWidth > 550 || this.state.pageWidth == 0 && <Col md={4}>
                          <Row title="Sum of All Items Cost * Total Qty">
                            <b className="text-right">Total Cost Value: ${this.state.totalStockCost >= 0 ? this.state.totalStockCost.toLocaleString(undefined, { minimumFractionDigits: 2 }) : <Spinner animation="border" size="sm" />}</b>
                          </Row>
                          <Row title="Sum of All Items Price * Total Qty">
                            <b className="text-right">Total Opportunity Value: ${this.state.totalOppCost >= 0 ? this.state.totalOppCost.toLocaleString(undefined, { minimumFractionDigits: 2 }) : <Spinner animation="border" size="sm" />}</b>
                          </Row>
                        </Col>}
                      </Row>
                    </Container>
                  </div>

                  <div className=" tablerow">

                    <LightningTable className="lightningFixedTable" height={`calc(100vh - ${props.footerRef.current ? props.footerRef.current.clientHeight : 0}px - ${props.headerRef.current ? props.headerRef.current.clientHeight : 0}px - ${this.headerBoxRef.current ? this.headerBoxRef.current.clientHeight : 0}px - ${this.filterBoxRef.current ? this.filterBoxRef.current.clientHeight : 0}px - 169px`} {...props} {...props.table}
                      onRowDoubleClick={(row) => { Object.keys(props.table.getState().rowSelection).length > 1 ? this.onRowDoubleClickBulkEdit(props.table.getFilteredSelectedRowModel()) : this.onRowDoubleClick(row.original) }}
                      noDataIndication={props.table.getCoreRowModel().rows.length > 0 ? <div>No result found for {this.state.searchWord}. <Button style={{ padding: "0px", marginTop: "-3px" }} variant="link" onClick={this.handleCustomClick}>Click Here</Button> to add it to the cart as a custom item.</div> : this.state.items.length != 0 && this.state.filtered_items == 0 ? "No Matching Results" : <Spinner animation="border" />}
                    />





                  </div>
                </div>
              )
            }

          </LightningKit>
        </div>

        {this.state.errorMessage &&
          <p className="errorTable"> {this.state.errorMessage} </p>}
      </div>
    );
  }
}

export default withRouter(InventoryPage);
