import React, { Fragment } from "react";


import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';

import { Table as BTable } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { DndProvider } from 'react-dnd';
import { DraggableColumnHeader } from "./lightning-utils";
import MyPagination from "./pagination";


import {
  flexRender,
} from '@tanstack/react-table';

import { HTML5Backend } from 'react-dnd-html5-backend';


function getActualTotalSize(table) {

  var start = table.getCenterTotalSize();

  table.getHeaderGroups().forEach(headerGroup => {

    headerGroup.headers.forEach(element => {
      if (element.column.columnDef.autoHide) {
        start -= element.getSize()
      }
    });
  });

  return start

}

export function getWidth(base, rawWidth) {

  if (base.column.columnDef.width && (base.column.columnDef.useFixed === undefined || base.column.columnDef.useFixed === false)) {
    //console.log(base.column.columnDef.MyRef.current.offsetWidth)
    return (base.column.columnDef.width * 1000).toString() + "%"
    //element.width +=(table.getState().columnSizingInfo.deltaOffset - lastSize.current)/parent_width
  } else {
    return rawWidth;
  }
}

export function LightningTable({ width, rowStyle, className, maxheight, tableRef, table, headerRef, bodyRef, footerRef, expandRow, pageOptions, showQtyPerPageDropdown, onRowClick, onRowDoubleClick, noDataIndication, height }) {

  const useDoubleClick = (onClick, onDbClick, delay = 300) => {
    const timePassed = React.useRef(0);
    return (e, row) => {
      if (e.detail === 1) {
        setTimeout(() => {
          if (Date.now() - timePassed.current >= delay) {
            onClick(row);
          }
        }, delay)
      }

      if (e.detail === 2) {
        timePassed.current = Date.now();
        onDbClick(row);
      }
    }
  }

  const dblClickCallback = useDoubleClick(onRowClick ? (row) => onRowClick(row) : () => { }, (row) => onRowDoubleClick ? onRowDoubleClick(row) : () => { })

  return <DndProvider backend={HTML5Backend}>
    <div style={{ width: width }}>

      <div>

        <div className="p-2">
          <BTable className={"lightningTable " + className} ref={tableRef} hover responsive {...{
            style: {
              width: "100%",//getActualTotalSize(table)//table.getCenterTotalSize(),
              borderBottom: "1px solid #c1c1c1"

            },
          }}>
            <thead ref={headerRef}>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.filter(header => !header.column.columnDef.autoHide).map(header => {
                    return (
                      <DraggableColumnHeader
                        key={header.id}
                        header={header}
                        table={table}
                      />
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody ref={bodyRef} style={{ height: height, maxHeight: maxheight ? maxheight : "100%" }}>
              {table.getRowModel().rows.length > 0 ? table.getRowModel().rows.map(row => {
                return (
                  <Fragment key={row.id}>
                    <tr style={rowStyle ? rowStyle(row.original) : {}} onClick={(e) => dblClickCallback(e, row)} className={`parent-${row.getIsExpanded() ? "expanded" : "collapsed"}`}>
                      {row.getVisibleCells().filter(cell => !cell.column.columnDef.autoHide).map(cell => (
                        <td {...{
                          key: cell.id,
                          style: {
                            width: getWidth(cell, cell.column.getSize()), // cell.column.columnDef.width ? (cell.column.columnDef.width*1000).toString() + "%" : cell.column.getSize(),
                            paddingTop: 10,
                            paddingBottom: 10,
                            ...cell.column.columnDef.style(cell, row)
                          },
                        }}>
                          {flexRender(cell.column.columnDef.editable && ((typeof cell.column.columnDef.editable === "function" && cell.column.columnDef.editable(row)) || typeof cell.column.columnDef.editable !== "function") ? cell.column.columnDef.Cell : cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                      <tr className="expanded-row" >
                        {/* 2nd row is a custom 1 cell row */}
                        <td className="no-hover" colSpan={row.getVisibleCells().length}>
                          {expandRow(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              }) : <tr><td className="no-hover" colSpan={100} style={{ textAlign: "center" }}>{noDataIndication}</td></tr>}
            </tbody>

          </BTable>
        </div>
      </div>

      {pageOptions && <Container className="lighningTable-pagination HideOnPrint" ref={footerRef} fluid style={{ marginTop: 10, textAlign: "left", marginBottom: 10, paddingBottom: 10 }}>
        <Row>
          <Col className="rowDisplay" style={{ paddingTop: 5 }} sm={4}>
            <span style={{ fontSize: "1rem" }}>Displaying rows {Math.min(((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize) - (table.getState().pagination.pageSize - 1), table.getPrePaginationRowModel().rows.length)}-{Math.min(((table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize), table.getPrePaginationRowModel().rows.length)} of {table.getPrePaginationRowModel().rows.length}</span>
          </Col>
          <Col sm={4}>
            <MyPagination
              totPages={table.getPageCount()}
              currentPage={table.getState().pagination.pageIndex + 1}
              pageClicked={(ele) => {
                table.setPageIndex(ele - 1);
              }}
            >

            </MyPagination>
          </Col>
          <Col sm={4}>
            {pageOptions && pageOptions.sizePerPageList && showQtyPerPageDropdown &&
              <Form.Select className="pageSizeSelect" style={{ maxWidth: 150, float: "right" }} aria-label="Select Page Size" onChange={e => {
                table.setPageSize(e.target.value);
              }} value={table.getState().pagination.pageSize}
              >
                {pageOptions.sizePerPageList.map(item => <option key={item.value} value={item.value}>Show {item.text}</option>
                )}

              </Form.Select>}
          </Col>
        </Row>
      </Container>}

    </div></DndProvider>;
}

